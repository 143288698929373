* {
    box-sizing: border-box;
}

html, body {
    margin: 0;
    padding: 0;
}

body {
    height: 100%;
    width: 100%;
    background-color: #f1f8e9; 
    /* overflow: none */
    -ms-overflow-style: none;
    scrollbar-width: none;
    font-family: Verdana, Arial, Helvetica, sans-serif
}

body::-webkit-scrollbar {
    display: none;
}

div#about.section {
    padding-top: 5rem;
    padding-bottom: 5rem;
}