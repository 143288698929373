.project {
    display: inline-block;
    position: relative;
    text-align: center
}

.project:hover .overlay {
    opacity: 1;
}

.circle {
    display: block;
    width: 100%;
    height: 100%
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: .5s ease;
    /* background-color: #33691e */
}

.description {
    color: white;
    font-size: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, 50%);
}
